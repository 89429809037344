/*globals $ */
// import jQuery from 'jquery';
// const $ = jQuery;


class ImgLazyLoad {
  constructor() {
    this.offsetTop = -200;
    this.mediaQuery = window.matchMedia('(max-width: 800px)')

    /*
    if(mainApp.viewMode == 'sp') {
      this.offsetTop = -100;
    }
    */

    if($('html').hasClass('ua-sp')) {
      this.offsetTop = -100;
    }
    this.elements = [];
    // this.init();

    $(window).on('resize', () => {
      this.onWindowResize();
    });
    this.onWindowResize();
  }
  onWindowResize() {
    /*
    if(mediaQuery.matches) {
      console.log('sp');
    } else {
      console.log('px');
    }
    */
  }

  clear() {
    this.elements = [];
  }


  init(){
    this.elements = [];
    $('.img-lazy').each((i, e) => {
      if($(e).hasClass('set-lazy') && !$(e).hasClass('loaded')) {
        $(e).removeClass('set-lazy')
      }
    });
    // #imgでdata-originalの属性があり、「set-lazy」クラスの付いていない要素を対象に設定
    let $lazy = $('.img-lazy:not(.set-lazy)');
    $lazy.each((i, el) => {
      let $el = $(el);
      this.elements.push($el);
      el.loaded = false;
      let src = $el.attr('data-original');
      let src_sp = $el.attr('data-original');
      if($el.attr('data-original-sp') != undefined) {
        src_sp = $el.attr('data-original-sp');
      }
      // #「appeer」eventをセットした要素には「set-lazy」クラスを付与

      let targetSrc = src;
      if(this.mediaQuery.matches) {
        targetSrc = src_sp;
      }
      $el.addClass('set-lazy');
      $el.one('appeer',() => {
        if(!el.loaded){
          $('<img>')
            .one('load',() => {
              if($el.prop('tagName') != 'IMG') {
                $el.css('background-image', 'url("' + targetSrc + '")');
              } else {
                $el.attr('src', targetSrc);
              }

              el.loaded = true;
              $el.addClass('loaded');
            })
            .attr('src', targetSrc);
        }
      });
    });
  }


  update(scroll, windowH){
    $.each(this.elements, (i, el) => {
      // if($(el).is(':hidden')) return;
      if($(el).hasClass('loaded')) return;
      if($(el).hasClass('hidden')) return;
      if(scroll > $(el).offset().top - windowH - this.offsetTop){
        $(el).trigger('appeer');
      }
    });
  }

}
export default ImgLazyLoad;
