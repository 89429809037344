/* global $ */

class SpMenu {
  constructor(mainApp) {
    this.mainApp      = mainApp;
    this.$btn         = $('.ToggleMenuBtn');
    this.isOpen       = false;
    this.$container   = $('.SpMenu');
    this.$panel       = this.$container.find('.SpMenuPanel');
    this.$lang        = this.$container.find('.SpMenu__lang');

    this.$btn.on('click', () => {
      if(this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    });
  }

  open() {
    if(!this.isOpen) {
      this.isOpen = true;
      $('body').addClass('sp-open-menu');
      this.$container.css('display', 'block');

      this.$panel.stop(false).animate({'right': 0}, 450, 'easeOutExpo', () => {
      });
      setTimeout(() => {
        this.$lang.css('opacity', 1);
      }, 100);
    }
  }
  close() {
    if(this.isOpen) {
      this.isOpen = false;
      $('body').removeClass('sp-open-menu');
      this.$lang.css('opacity', 0);
      // this.$container.css('display', 'none');
      this.$panel.stop(false).animate({'right': -360}, 400, 'easeOutExpo', () => {
        this.$container.css('display', 'none');
      });
    }
  }
}

export default SpMenu;
