/* global $ */

class SyncGrid {
  constructor() {
    this.$el = $('.SyncGrid');
    this.$col_1 = this.$el.find('.Grid__col--1-6');
    this.$col_2 = this.$el.find('.Grid__col--2-6');
    this.$col_3 = this.$el.find('.Grid__col--3-6');
    this.$col_4 = this.$el.find('.Grid__col--4-6');
    this.$col_5 = this.$el.find('.Grid__col--5-6');
  }
  onWindowResize() {
    $('.SyncGridWidth--3-6').css('width', this.$col_3.width());
  }
}

export default SyncGrid;
