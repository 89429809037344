/* global $ */

import EffectImg from "./EffectImg";
import EffectText from "./EffectText";
import AnimateLoader from "./AnimateLoader";

class LazyLoader {
  constructor($el) {
    this.$el      = $el;
    this.loaded   = false;
    this.src      = this.$el.attr('data-original');

    this.$el.addClass('set-lazy');
    this.$el.one('appeer', () => {
      if(!this.loaded) {
        $('<img>')
          .one('load',() => {
            if(this.$el.prop('tagName') != 'IMG') {
              this.$el.css('background-image', 'url("' + this.src + '")');
            } else {
              this.$el.attr('src', this.src);
            }

            this.loaded = true;
            this.$el.addClass('loaded');
          })
          .attr('src', this.src);
      }
    });
  }

  fadein() {
    this.$el.trigger('appeer');
  }
}


class InviewObj {
  constructor($el) {

    this.$el            = $el;

    this.hasStrokeText  = false;
    this.hasPageTitle   = false;
    this.offsetTop      = -100;
    this.isLoaded       = false;
    this.delay          = 0;
    this.fadeinOffset   = 0;

    this.isDebug        = false;

    this.counter = undefined;
    this.lazyImage = undefined;
    this.isEffectImg = false;
    this.isWaitOnload = false;
    this.isEffectText = false;

    this.isAnimateLoader = false;
    this.isAnimate = false;

    if(this.$el.attr('data-wait-onload') != undefined) {
      if(this.$el.attr('data-wait-onload') == 'true') {
        this.isWaitOnload = true;
        // console.log('this.isWaitOnload = true;');
      }
    }

    if(this.$el.attr('data-inview-offset') != undefined) {
      if(this.$el.attr('data-inview-offset') != '') {
        this.offsetTop = -parseInt(this.$el.attr('data-inview-offset'));
      }
    }

    if(this.$el.attr('data-fadein-delay') != undefined) {
      if(this.$el.attr('data-fadein-delay') != '') {
        this.delay = parseInt(this.$el.attr('data-fadein-delay'));
      }
    }


    if(this.$el.hasClass('LazyImg')) {
      this.lazyImage = new LazyLoader(this.$el);
    }
    if(this.$el.hasClass('EffectText')) {
      this.isEffectText = true;
      this.effectText = new EffectText(this.$el);
    }
    if(this.$el.hasClass('EffectImg')) {
      this.isEffectImg = true;
      this.effectImg = new EffectImg(this.$el);

    }
    if(this.$el.hasClass('AnimateContainer')) {
      this.isAnimate = true;
    }

    if(this.$el.hasClass('AnimateLoader')) {
      this.animateLoader = new AnimateLoader(this.$el);
      this.isAnimateLoader = true;
    }
    /*
    if(this.$el.hasClass('EffectImg') && this.$el.find('.ImgWrap__inner')) {
      if(this.$el.find('.ImgWrap__inner').attr('data-original') != undefined) {
        this.isEffectImg = true;
        this.lazyImage = new LazyLoader(this.$el.find('.ImgWrap__inner'));
      }
    }
    */


  }

  checkLoadImageStatus() {
    this.lazyImage.fadein();
    this.loadWaitInterval = setInterval(() => {
      if(this.lazyImage.loaded) {
        clearInterval(this.loadWaitInterval);
        this.$el.addClass('fadein');
      }
    }, 1000 / 30);
  }

  update(scrollTop, windowH) {
    if(this.isLoaded) return;
    if(scrollTop > this.$el.offset().top - windowH - this.offsetTop){

      setTimeout(() => {
        if(this.isEffectImg) {
          this.effectImg.fadein();
        } else if(this.isAnimate) {
          window.init();
        } else if(this.isAnimateLoader) {
          this.animateLoader.fadein();
        } else if(this.isEffectText) {
          this.effectText.fadein();
        } else {
          if(this.isWaitOnload) {
            this.loadWaitInterval = setInterval(() => {
              if(window.onLoadComplete) {
                clearInterval(this.loadWaitInterval);
                this.fadein();
              }
            }, 1000 / 30);
          } else {
            this.fadein();
          }

        }
      }, this.delay);

      this.isLoaded = true;
    }
  }

  fadein() {
    this.$el.addClass('fadein');

    /*
    if(this.lazyImage) {
      this.lazyImage.fadein();
    }
    */
  }
}

export default InviewObj;
