/* global $ */

class EffectImg {
  constructor($el) {
    this.$el      = $el;
    this.$back    = this.$el.find('.EffectImg__bg');
    this.$front   = this.$el.find('.EffectImg__front');

    $(window)
      .on('resize', ()=>{
        this.onWindowResize();
      });
    this.onWindowResize();
  }

  fadein() {
    this.loadWaitInterval = setInterval(() => {
      if(this.$back.hasClass('loaded')) {
        clearInterval(this.loadWaitInterval);
        this.$el.addClass('img-loaded');
      }

    }, 1000 / 30);
  }
  onWindowResize() {
    this.$front.css('width', this.$back.width());
  }
}

export default EffectImg;
