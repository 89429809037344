import jQuery from "jquery";
import "jquery.easing";
import AutoFitWidth from "./modules/AutoFitWidth";
import EffectText from "./modules/EffectText";

const $ = jQuery;
window.jQuery = $;
window.$ = $;

import ImgLazyLoad from "./modules/img-lazy-load";
import ImgBlock from "./modules/ImgBlock";
import MainNavListItemBtn from "./modules/MainNavListItemBtn";
import Slideshow from "./modules/Slideshow";
import SpMenu from "./modules/SpMenu";
import SyncGrid from "./modules/SyncGrid";
import InviewObj from "./modules/InviewObj";
import SyncObject from "./modules/SyncObject";
// import AnimateLoader from './modules/AnimateLoader';

class MainApp {
  isMobile() {
    let is_mobile = false;
    if (navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
      is_mobile = true;
    }
    return is_mobile;
  }
  constructor() {
    this.$window = $(window);
    this.fxImgSet = [];
    this.imgLazyLoad = new ImgLazyLoad(this);
    this.syncGrid = new SyncGrid();
    this.spMenu = new SpMenu(this);
    this.isHome = false;

    this.initialInnerHeight = window.innerHeight;
    this.isFixedHeight = false;

    if ($("body").hasClass("home")) {
      this.isHome = true;
    }

    if ($(".Form").length) {
      if (
        $(".FormRow--acceptance").find('input[type="checkbox"]').prop("checked")
      ) {
        $(".FormBtnWrap--confirm").removeClass("disabled");
      }
      $(".FormRow--acceptance")
        .find('input[type="checkbox"]')
        .on("change", (e) => {
          if ($(e.currentTarget).prop("checked")) {
            $(".FormBtnWrap--confirm").removeClass("disabled");
          } else {
            $(".FormBtnWrap--confirm").addClass("disabled");
          }
        });
    }
    if ($("body").hasClass("page-template-page-contact")) {
      if (window.location.href.indexOf("contact/confirm") !== -1) {
        $(".FormBtnWrap--confirm").removeClass("disabled");
        $(".FormRow--acceptance, .FormRow--notice, .FormUI__attention").css(
          "display",
          "none"
        );
        $(".Form").addClass("confirm");
        $(".Form__message--top").css("display", "block");
      } else if (window.location.href.indexOf("contact/thanks") !== -1) {
        $(".contact-introduction").css("display", "none");
        $(".Form__message--top").css("display", "block");
      } else {
        $(".FormBtnWrap--back").addClass("hide");
      }

      // contact-introduction
    }

    $(".MainNavListItemBtn--has-dropdown").each((i, e) => {
      new MainNavListItemBtn($(e));
    });

    /*
    if(!this.isTouchDevice()) {
    }
    */

    new AutoFitWidth();

    if ($(".Slideshow").length) {
      new Slideshow();
    }

    if ($(".ImgBlock").length) {
      $(".ImgBlock").each((i, e) => {
        if (
          !$(e).hasClass("ImgBlock--full") &&
          !$(e).hasClass("ImgBlock--half")
        ) {
          new ImgBlock($(e));
        }
      });
    }

    this.fxImgSet = [];
    this.syncObjectSet = [];

    $(".InviewObj").each((i, e) => {
      this.fxImgSet.push(new InviewObj($(e)));
    });

    $(document).on("touchstart", "a, button", (e) => {
      $(e.currentTarget).attr("data-touch", "true");
    });

    $(document).on("mouseleave", "a, button", (e) => {
      $(e.currentTarget).removeAttr("data-touch");
    });

    $(".FormBtn--back").on("click", (e) => {
      $(".wpcf7-form").removeClass("custom-wpcf7c-confirmed");
      $(".wpcf7-form").addClass("init");
      $(".wpcf7-form").attr("data-status", "init");
    });

    $(".BtnPagetop").on("click", () => {
      this.scrollPage(0);
    });

    $(".ScrollDownBtn").on("click", () => {
      let headerHeight = 120;
      this.scrollPage(
        $("#Section--home-mission").offset()["top"] - headerHeight
      );
    });

    this.scrollableElement = null;
    let firefox = navigator.userAgent.match(/Firefox\/([0-9]+)\./);
    if ("scrollingElement" in document) {
      this.scrollableElement = document.scrollingElement;
    } else if (
      /*@cc_on!@*/ false ||
      (!!window.MSInputMethodContext && !!document.documentMode)
    ) {
      this.scrollableElement = document.documentElement;
    } else if (firefox && parseInt(firefox[1]) <= 47) {
      this.scrollableElement = document.documentElement;
    } else {
      this.scrollableElement = document.body;
    }

    this.onWindowScroll();
  }

  init() {
    if (this.initialInnerHeight > window.innerHeight && !this.isFixedHeight) {
      this.isFixedHeight = true;
      this.initialInnerHeight = window.innerHeight;
    }

    if (this.isMobile() && this.isHome) {
      $(".Hero").css("height", this.initialInnerHeight - 30);
    }

    if ($(".EffectText").length) {
      $(".EffectText").each((i, e) => {
        if (!$(e).hasClass("initialized")) {
          new EffectText($(e));
        }
      });
    }

    if ($(".SyncObject--parent").length) {
      $(".SyncObject--parent").each((i, e) => {
        this.syncObjectSet.push(new SyncObject($(e)));
      });
    }

    this.$window
      .on("resize", () => {
        this.onWindowResize();
      })
      .on("scroll", () => {
        this.onWindowScroll();
      });
    this.onWindowResize();

    this.initPageApp();
  }

  initPageApp() {
    this.imgLazyLoad.clear();
    this.imgLazyLoad.init();

    $(".Header").addClass("Header--fadein");

    setTimeout(() => {
      $(".Hero__scroll").addClass("Hero__scroll--initialized");
    }, 1000);

    this.onWindowScroll();
    if (this.isMobile() && this.isHome) {
      this.scrollPage(1, true);
    }
  }

  isTouchDevice() {
    return "ontouchstart" in window || navigator.msPointerEnabled
      ? true
      : false;
  }

  scrollPage(val, isCancelTransition = false) {
    if (isCancelTransition) {
      $(this.scrollableElement).stop(false).scrollTop(val);
    } else {
      $(this.scrollableElement)
        .stop(false)
        .animate({ scrollTop: val }, 1200, "easeInOutExpo");
    }
  }

  onWindowResize() {
    this.windowWidth = this.$window.width();
    this.windowHeight = this.$window.height();

    for (let i = 0; i < this.syncObjectSet.length; i++) {
      this.syncObjectSet[i].onWindowResize();
    }

    this.syncGrid.onWindowResize();

    this.onWindowScroll();
  }

  onWindowScroll() {
    this.scrollTop = this.$window.scrollTop();

    if (this.isHome) {
      if (this.scrollTop > 100) {
        $(".Header").addClass("Header--has-bg");
        $(".Hero__scroll").addClass("Hero__scroll--fadeout");
      } else {
        $(".Header").removeClass("Header--has-bg");
        $(".Hero__scroll").removeClass("Hero__scroll--fadeout");
      }
    }

    this.imgLazyLoad.update(this.scrollTop, this.windowHeight);

    if (this.fxImgSet) {
      for (let i = 0; i < this.fxImgSet.length; i++) {
        this.fxImgSet[i].update(this.scrollTop, this.windowHeight);
      }
    }
  }
}

let mainApp = undefined;
$("document").ready(() => {
  mainApp = new MainApp();
});

$(window).on("load", () => {
  let onLoadInterval = setInterval(() => {
    if (mainApp != undefined) {
      clearInterval(onLoadInterval);
      mainApp.init();
    }
  }, 1000 / 30);
});
