/* global $ */

class SyncObject {
  constructor($el) {
    this.$el      = $el;
    this.id       = this.$el.attr('data-sync-id');
    this.mode     = this.$el.attr('data-sync-mode');
    this.$child   = $('.SyncObject--child[data-sync-id="' + this.id + '"]');
  }

  onWindowResize() {
    if(this.mode == 'height') {
      this.$child.css('height', this.$el.outerHeight());
    }
  }
}

export default SyncObject;
