/* global $ */

class SlideshowItem {
  constructor($el) {
    this.$el      = $el;
    this.src      = this.$el.attr('data-src');
    this.$back    = this.$el.find('.SlideshowItem__img--back');
    this.$front   = this.$el.find('.SlideshowItem__img--front');
    this.$mask    = this.$el.find('.SlideshowItem__img--mask');

    $('<img>')
      .one('load',() => {
        this.$el.find('.SlideshowItem__img').each((i, e) => {
          $(e).css('background-image', 'url(' + this.src + ')');
        });
      })
      .attr('src', this.src);
  }

  fadein(index) {
    this.$el.css('z-index', index);
    setTimeout(() => {
      this.$el.addClass('fadein');
    }, 50);

  }
  fadeout() {
    this.$el.removeClass('fadein');
  }

  onWindowResize() {
    this.$front.css('width', this.$back.width());
  }
}


class Slideshow {
  constructor() {
    this.$container     = $('.Slideshow');
    this.items          = [];
    this.currentImage   = 0;
    this.index          = 1;

    this.$container.find('.SlideshowItem').each((i, e) => {
      this.items.push(new SlideshowItem($(e)));
    });

    let slide_total = String(this.items.length);
    if(slide_total.length == 1) slide_total = '0' + slide_total;
    $('.VerticalPagination__bottom').text(slide_total);

    $(window).on('resize', () => {
      this.onWindowResize();
    });

    this.onWindowResize();

    setTimeout(() => {
      this.items[0].fadein(this.index);

      if(this.items.length > 1) {
        setTimeout(() => {
          this.next();
        }, 5000);

      }
    }, 500);

  }
  next() {
    this.currentImage++;
    this.index++;
    if(this.currentImage >= this.items.length) {
      this.currentImage = 0;
    }
    let showIndex = String(this.currentImage + 1);
    if(showIndex.length == 1) showIndex = '0' + showIndex;
    $('.VerticalPagination__top').text(showIndex);

    this.items[this.currentImage].fadein(this.index);
    setTimeout(() => {
      for(let i = 0; i < this.items.length; i++) {
        if(i != this.currentImage) {
          this.items[i].fadeout();
        }
      }
    }, 2000);

    setTimeout(() => {
      this.next();
    }, 5000);
  }

  onWindowResize() {
    this.$container.css('width', parseInt(window.innerWidth - this.$container.offset()['left']));

    for(let i = 0; i < this.items.length; i++) {
      this.items[i].onWindowResize();
    }


  }
}

export default Slideshow;
