/* global $ */

class ImgBlock {
  constructor($el) {
    console.log('ImgBlock');
    this.$container = $el;
    this.$inner = this.$container.children('.ImgBlock__inner');
    this.base = 'left';

    if(this.$container.hasClass('ImgBlock--base-right')) {
      this.base = 'right';
    }

    $(window).on('resize', () => {
      this.onWindowResize();
    });

    this.onWindowResize();
  }

  onWindowResize() {
    let _width = this.$container.width();

    if(this.base == 'right') {
      let diff = window.innerWidth - (this.$container.offset()['left'] + _width);
      this.$inner.css('width', parseInt(window.innerWidth - diff) + 1);
    } else if(this.base == 'left') {
      this.$inner.css('width', parseInt(window.innerWidth - this.$container.offset()['left']) + 1);
    }


  }
}

export default ImgBlock;
