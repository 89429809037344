/*globals $ */

class Dropdown {
  constructor($el) {
    this.$el          = $el;
    this.$accordion   = this.$el.find('.Dropdown__accordion');
    this.$content     = this.$el.find('.DropdownList');
  }
  open() {
    this.$accordion.stop(false).animate({'height': parseInt(this.$content.height())}, 400, 'easeOutExpo');
  }
  close() {
    this.$accordion.stop(false).animate({'height': 0}, 400, 'easeOutExpo');
  }
}

class MainNavListItemBtn {
  constructor($el) {
    this.$el      = $el;
    this.isHover  = false;

    if(this.$el.find('.Dropdown').length) {
      this.$el.find('.Dropdown').each((i, e) => {
        this.dropdown = new Dropdown($(e));
      });
    }

    if(!this.isTouchDevice()) {
      this.$el.mouseover(() => {
        clearInterval(this.mouseoutTimer);
        if(!this.isHover) {
          this.isHover = true;
          this.$el.addClass('MainNavListItemBtn--hover');
          if(this.dropdown != undefined) this.dropdown.open();
        }
      });

      this.$el.mouseout(() => {
        clearInterval(this.mouseoutTimer);
        this.mouseoutTimer = setInterval(() => {
          clearInterval(this.mouseoutTimer);
          this.isHover = false;
          this.$el.removeClass('MainNavListItemBtn--hover');
          if(this.dropdown != undefined) this.dropdown.close();
        }, 50);
      });
    }
  }

  isTouchDevice() {
    return ('ontouchstart' in window || navigator.msPointerEnabled) ? true : false;
  }
}

export default MainNavListItemBtn;
