/* global $ */

class AutoFitWidth {
  constructor() {
    this.$items = [];


    if($('.AutoFitWidth').length) {
      $('.AutoFitWidth').each((i, e) => {
        this.$items.push($(e));
      });

      $(window).on('resize', () => {
        this.onWindowResize();
      });
      this.onWindowResize();
    }
  }
  onWindowResize() {
    for(let i = 0; i < this.$items.length; i++) {
      let _el = this.$items[i];
      let base = 'left';
      if(_el.attr('data-autofit-base') != undefined) {
        base = _el.attr('data-autofit-base');
      }

      if(base == 'left') {
        let _left = this.$items[i].offset()['left'];
        this.$items[i].css('width', parseInt(window.innerWidth - _left));
      } else if(base == 'right') {
        let _parent = _el.parent();
        let _parentLeft = _parent.offset()['left'];
        let _parentWidth = _parent.width();
        let _diff = window.innerWidth - (_parentLeft + _parentWidth);
        this.$items[i].css('width', parseInt(window.innerWidth - _diff));
      }

    }
  }
}

export default AutoFitWidth;
