/* global $ */

class AnimateLoader {
  constructor($container) {
    this.$container   = $container;
    this.id           = this.$container.attr('id');

    this.iframeStyle = {
      'position': 'absolute',
      'top': 0,
      'left': 0,
      'width': '100%',
      'height': '100%',
      'background': 'none'
    };

    $('#' + this.id).on('load', () => {
      this.onWindowResize();
    });

    $(window).on('resize', () => {
      this.onWindowResize();
    });

  }

  fadein() {
    let iframe = document.getElementById(this.id);
    iframe.contentWindow.init();
    this.onWindowResize();
  }

  onWindowResize() {
    $('#' + this.id).contents().find('#animation_container').css(this.iframeStyle);
    $('#' + this.id).contents().find('#canvas').css(this.iframeStyle);
    $('#' + this.id).contents().find('#dom_overlay_container').css(this.iframeStyle);
  }
}

export default AnimateLoader;
