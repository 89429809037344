/* global $ */

class EffectTextPart {
  constructor(parent, part, text) {
    this.parent       = parent;
    this.masterText   = text;
    this.part         = part;
    this.textLines    = this.masterText.split('<br>');
    this.$lines       = [];

    if(this.part == 'back') {
      this.$container = $('<div class="EffectTextBack"></div>');
    } else if(this.part == 'front') {
      this.$container = $('<div class="EffectTextFront"></div>');
    }

    let linesHtmlText = '';
    for(let i = 0; i < this.textLines.length; i++) {
      if(i > 0) {
        linesHtmlText += '<br>';
      }
      linesHtmlText += `<div class="EffectText__line"><span><span>${this.textLines[i]}</span></span></div>`;
    }

    this.$container.append($(linesHtmlText));
    this.parent.$container.append(this.$container);

    this.$container.find('.EffectText__line').each((i, e) => {
      this.$lines.push($(e));
    });
  }

  fadein() {
    for(let i = 0; i < this.$lines.length; i++) {
      setTimeout(() => {
        this.$lines[i].addClass('--fadein');
      }, i * 200);
    }
  }
}

class EffectText {
  constructor($container) {


    this.$container = $container;
    this.masterText = this.$container.html();
    this.$container.html('');
    this.$container.addClass('initialized');

    this.back = new EffectTextPart(this, 'back', this.masterText);
    this.front = new EffectTextPart(this, 'front', this.masterText);

    if(!this.$container.hasClass('InviewObj')) {
      this.fadein();
    }
  }
  fadein() {
    this.back.fadein();
    setTimeout(() => {
      this.front.fadein();
    }, 250);
  }
}

export default EffectText;
